import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAc8crVqZKXcFqfsjIRUSP0ksQUabxu6eI',
  authDomain: 'ton-app-2012a.firebaseapp.com',
  projectId: 'ton-app-2012a',
  storageBucket: 'ton-app-2012a.appspot.com',
  messagingSenderId: '563624793491',
  appId: '1:563624793491:web:6736ac1e3d6b7b3efdc466',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
