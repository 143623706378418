import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { db } from "../lib/firebase";
import { Item } from "../lib/types";
import FeaturedItem from "./FeaturedItem";

const FeaturedItems = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchItems = async () => {
      const q = query(collection(db, "items"), orderBy("order"));
      const querySnapshot = await getDocs(q);
      const fetchedItems = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Item[];
      setItems(fetchedItems);
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const checkOverflow = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } =
          scrollContainerRef.current;
        setShowRightArrow(
          scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth
        );
        setShowLeftArrow(scrollLeft > 0);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", checkOverflow);
    }

    return () => {
      window.removeEventListener("resize", checkOverflow);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", checkOverflow);
      }
    };
  }, [items]);

  const handleScroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <section className=" py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold font-serif mb-2 text-black">
          Featured Accessories
        </h2>
        <div className="bg-black p-3 rounded-lg shadow-lg relative">
          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto scrollbar-hide"
            style={{
              scrollSnapType: "x mandatory",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {items.map((item) => (
              <div
                key={item.id}
                className="flex-shrink-0 w-48 mr-4"
                style={{ scrollSnapAlign: "start" }}
              >
                <FeaturedItem item={item} />
              </div>
            ))}
          </div>
          {showLeftArrow && (
            <button
              onClick={() => handleScroll("left")}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            >
              ←
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => handleScroll("right")}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
            >
              →
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedItems;
