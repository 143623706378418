import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ProductPageSkeleton() {
  return (
    <div className="flex flex-col lg:flex-row mt-4">
      {/* Image Carousel Skeleton */}
      <div className="relative w-full lg:w-1/2 aspect-square px-4">
        <Skeleton height="100%" />
      </div>

      {/* Product Info Skeleton */}
      <div className="w-full lg:w-1/2 lg:pl-5 lg:mt-0 px-4">
        {/* Brand and Model */}
        <div className="mb-2">
          <Skeleton width="50%" height={35} />
        </div>

        {/* Condition */}
        <div className="mb-2">
          <Skeleton width="30%" height={20} />
        </div>

        {/* Price */}
        <div className="mb-4">
          <Skeleton width="40%" height={40} />
        </div>

        {/* Buy Button */}
        <div className="mt-6">
          <Skeleton width="100%" height={40} />
        </div>

        {/* Product Details */}
        <div className="mt-6">
          <h1 className="font-serif text-2xl font-bold">
            <Skeleton width="30%" />
          </h1>

          {/* Color and Material */}
          <div className="mt-2">
            <h2 className="font-sans font-bold">
              <Skeleton width="30%" />
            </h2>
            <p>
              <Skeleton width="50%" />
            </p>
          </div>

          {/* Measurements */}
          <div className="mt-2">
            <h2 className="font-sans font-bold">
              <Skeleton width="30%" />
            </h2>
            <p>
              <Skeleton width="40%" />
            </p>
            <p>
              <Skeleton width="40%" />
            </p>
            <p>
              <Skeleton width="40%" />
            </p>
          </div>
        </div>

        {/* Description */}
        <div className="mt-4 pb-2">
          <p className="font-sans text-lg font-bold">
            <Skeleton width="30%" />
          </p>
          <p>
            <Skeleton count={3} />
          </p>
        </div>
      </div>
    </div>
  );
}
