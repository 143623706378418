import React from 'react';
import { Link } from 'react-router-dom';

const brands = [
  { name: 'Chanel', src: 'brands/chanel.png', width: '200px' },
  { name: 'Celine', src: 'brands/celine.png', width: '200px' },

  { name: 'Hermès', src: 'brands/hermes.png', width: '200px' },
  {
    name: 'Louis Vuitton',
    src: 'brands/louis_vuitton.png',
    width: '200px',
  },
  { name: 'Christian Dior', src: 'brands/dior.png', width: '200px' },
  { name: 'Loewe', src: 'brands/loewe.png', width: '200px' },
  { name: 'Bottega Veneta', src: 'brands/bottega_veneta.png', width: '200px' },
  { name: 'Balenciaga', src: 'brands/balenciaga.png', width: '200px' },
  { name: 'Gucci', src: 'brands/gucci.png', width: '200px' },
];

const otherBrands = [
  { value: 'Balmain', label: 'Balmain' },
  { value: 'Chloé', label: 'Chloé' },
  { value: 'Fendi', label: 'Fendi' },
  { value: 'Prada', label: 'Prada' },
  { value: 'Goyard', label: 'Goyard' },
  { value: 'Jacquemus', label: 'Jacquemus' },
  { value: 'Miu Miu', label: 'Miu Miu' },
  { value: 'Yves Saint Laurent', label: 'Saint Laurent' },
  { value: 'Burberry', label: 'Burberry' },
  { value: 'Versace', label: 'Versace' },
  { value: 'Mulberry', label: 'Mulberry' },
  { value: 'Totême', label: 'Totême' },
];

const BrandPage = () => {
  return (
    <div className="container mx-auto pb-16">
      <h1 className="text-3xl font-serif mt-4 mb-2 text-center">
        Popular Brands
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-items-center gap-y-16 sm:gap-y-24 p-8 md:grid-cols-3">
        {brands.map(brand => (
          <Link
            key={brand.name}
            to={`/products?brand=${encodeURIComponent(brand.name)}`}
            className="w-48 flex items-center justify-center"
          >
            <img src={brand.src} alt={brand.name} />
          </Link>
        ))}
      </div>
      <div className="flex flex-col sm:flex-row m-4 p-4 gap-8 mt-8 align-middle sm:place-items-center rounded-lg bg-white text-center">
        <div className="flex-1 hidden sm:flex">
          <img src="brands.jpg" className="object-cover" />
        </div>
        <div className="flex-1 flex-col">
          <h2 className="text-2xl font-serif mb-2">Other Brands</h2>
          {otherBrands.map(brand => (
            <Link
              key={brand.value}
              to={`/products?brand=${encodeURIComponent(brand.label)}`}
              className="flex flex-col items-center text-center"
            >
              {brand.value}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
