import React from "react";
import { Product } from "../../lib/types";
import { RiShoppingBag4Line } from "react-icons/ri";

function BuyButton({ product, mods }: { product: Product; mods?: string }) {
  return (
    <button
      className={`btn btn-primary flex space-x-1 items-center justify-center rounded-none ${
        mods ?? ""
      }`}
      id="buy-button"
      title={`Buy now at ${product.store}`}
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "purchase",
            product_id: product.id,
            product_name: `${product.brand} ${product.model}`,
            product_price: product.price,
            product_discount: product.discount,
            product_currency: product.currency,
            brand: product.brand,
            model: product.model,
            store: product.store,
            condition: product.condition,
            url: product.productUrl,
            material: product.material,
            color: product.color,
            categories: product.categories,
          });
        }
        const leavingPageUrl = new URL("/leaving", window.location.origin);
        leavingPageUrl.searchParams.set("externalUrl", product.productUrl);
        leavingPageUrl.searchParams.set("store", product.store);
        window.open(leavingPageUrl.toString(), "_blank");
      }}
    >
      <RiShoppingBag4Line />
      <p className="truncate">{`Buy at ${product.store}`}</p>
    </button>
  );
}

export default BuyButton;
