import React, { useState, useRef, useEffect } from "react";
import { Item } from "../lib/types";
import {
  RiShoppingBag4Line,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import Price from "./Price";

interface FeaturedItemProps {
  item: Item;
}

const BuyNowButton = ({ item, mods }: { item: Item; mods?: string }) => {
  return (
    <button
      className={`btn btn-primary flex space-x-1 items-center justify-center rounded-none ${
        mods ?? ""
      }`}
      id="buy-button"
      title="Buy now"
      onClick={() => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "purchase",
            product_name: item.title,
            product_price: item.price,
            product_currency: "SEK", // FIXME: hardcoded
            store: item.store,
            url: item.externalUrl,
            categories: ["accessories"], // FIXME: hardcoded
          });
        }
        const leavingPageUrl = new URL("/leaving", window.location.origin);
        leavingPageUrl.searchParams.set("externalUrl", item.externalUrl);
        leavingPageUrl.searchParams.set("store", item.store);
        window.open(leavingPageUrl.toString(), "_blank");
      }}
    >
      <RiShoppingBag4Line />
      <p className="truncate">Buy now</p>
    </button>
  );
};

const FeaturedItem: React.FC<FeaturedItemProps> = ({ item }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? item.imageUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === item.imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "unset";
    };
  }, [isModalOpen]);

  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg bg-white group h-full">
      <div
        className="w-full aspect-square overflow-hidden cursor-pointer"
        onClick={handleImageClick}
      >
        <img
          src={item.imageUrl}
          alt={item.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h1 className="text-md font-semibold font-serif line-clamp-2 h-10 leading-tight">
          {item.title}
        </h1>
        {item.price && (
          <p className="text-md font-medium mb-2">
            <Price amount={item.price} currency={"SEK"} />
          </p>
        )}
        <div className="mt-auto">
          <BuyNowButton
            item={item}
            mods="text-sm px-2 shadow-md rounded-md w-full"
          />
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={handleCloseModal}
        >
          <button
            className="absolute top-4 right-4 bg-white rounded-full text-black px-4 py-2 flex items-center"
            onClick={handleCloseModal}
          >
            <RiCloseLine className="mr-1" />
            Close
          </button>
          <div
            ref={modalRef}
            className="relative max-w-4xl max-h-full"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={item.imageUrls[currentImageIndex]}
              alt={`${item.title} - Image ${currentImageIndex + 1}`}
              className="max-w-full max-h-[90vh] object-contain"
              loading="eager"
            />

            <button
              className="absolute border border-black left-4 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2"
              onClick={handlePrevImage}
            >
              <RiArrowLeftSLine className="text-3xl " />
            </button>
            <button
              className="absolute border border-black right-4 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2"
              onClick={handleNextImage}
            >
              <RiArrowRightSLine className="text-3xl" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedItem;
