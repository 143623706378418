import algoliasearch from "algoliasearch/lite";
import { MultiValue } from "react-select";
import { brandMap, conditionMap, Option, sortMap } from "./types";

const client = algoliasearch("NNM0SWKG0Y", "0676db45d8d672f8228e2b8578e92129");

const algoliaIndexes = {
  default: client.initIndex("algolia_index_relevance"),
  priceAsc: client.initIndex("algolia_index_price_asc"),
  priceDesc: client.initIndex("algolia_index_price_desc"),
  conditionAsc: client.initIndex("algolia_index_quality_asc"),
  conditionDesc: client.initIndex("algolia_index_quality_desc"),
  createdAt: client.initIndex("algolia_index_new"),
  brand: client.initIndex("algolia_index_brand"),
  priceDiff: client.initIndex("algolia_index_pricediff_asc"),
  discount: client.initIndex("algolia_index_discount"),
};

export interface Query {
  search: string;
  sortBy: Option | null;
  category: Option | null;
  brands: MultiValue<Option>;
  conditions: MultiValue<Option>;
  minPrice: string;
  maxPrice: string;
  isDiscounted: boolean;
  //isSoldOut: boolean;
  page: number;
  model: string;
  store: string;
}

export const defaultQuery: Query = {
  search: "",
  sortBy: { value: "default", label: "Relevance" },
  category: null,
  brands: [],
  conditions: [],
  minPrice: "",
  maxPrice: "",
  isDiscounted: false,
  //isSoldOut: false,
  page: 1,
  model: "",
  store: "",
};

export async function search(
  query: Query,
  currentPage: number,
  productsPerPage: number
): Promise<{ hits: string[]; totalPages: number }> {
  const getCurrentAlgoliaIndex = () => {
    const indexKey = (query.sortBy?.value ||
      "default") as keyof typeof algoliaIndexes;
    return algoliaIndexes[indexKey];
  };

  try {
    const currentIndex = getCurrentAlgoliaIndex();

    const algoliaFilters: string[] = [];

    if (query.category)
      algoliaFilters.push(`(categories:${query.category.value})`);

    if (query.brands.length > 0) {
      const brandFilters = query.brands
        .map((brand) => `brand:"${brand.value}"`)
        .join(" OR ");
      algoliaFilters.push(`(${brandFilters})`);
    }

    if (query.conditions.length > 0) {
      const conditionFilters = query.conditions
        .map((condition) => `condition:"${condition.value}"`)
        .join(" OR ");
      algoliaFilters.push(`(${conditionFilters})`);
    }

    if (query.minPrice)
      algoliaFilters.push(`(sortPrice >= ${parseFloat(query.minPrice)})`);

    if (query.maxPrice)
      algoliaFilters.push(`(sortPrice <= ${parseFloat(query.maxPrice)})`);

    if (query.store) algoliaFilters.push(`(store:"${query.store}")`);

    //algoliaFilters.push(`(sold:${query.isSoldOut})`);
    algoliaFilters.push(`(sold:false)`);
    if (query.isDiscounted) algoliaFilters.push(`(discount > 0)`);

    if (window.location.hostname === "localhost") {
      console.log(
        "ALGOLIA - search:",
        query.search + ", filters: " + algoliaFilters.join(" AND ")
      );
    }

    const { hits, nbPages } = await currentIndex.search<{ objectID: string }>(
      query.search,
      {
        filters: algoliaFilters.join(" AND "),
        hitsPerPage: productsPerPage,
        page: currentPage - 1,
        typoTolerance: "min",
      }
    );

    return { hits: hits.map((hit) => hit.objectID), totalPages: nbPages };
  } catch (error) {
    console.error("Algolia search error:", error);
    return { hits: [], totalPages: 0 };
  }
}

export async function fuzzySearch(
  query: Query,
  currentPage: number,
  productsPerPage: number
): Promise<{ hits: string[]; totalPages: number }> {
  const getCurrentAlgoliaIndex = () => {
    const indexKey = (query.sortBy?.value ||
      "default") as keyof typeof algoliaIndexes;
    return algoliaIndexes[indexKey];
  };

  try {
    const currentIndex = getCurrentAlgoliaIndex();

    const algoliaFilters: string[] = [];

    if (query.category)
      algoliaFilters.push(`(categories:${query.category.value})`);

    if (query.brands.length > 0) {
      const brandFilters = query.brands
        .map((brand) => `brand:"${brand.value}"`)
        .join(" OR ");
      algoliaFilters.push(`(${brandFilters})`);
    }

    if (query.conditions.length > 0) {
      const conditionFilters = query.conditions
        .map((condition) => `condition:"${condition.value}"`)
        .join(" OR ");
      algoliaFilters.push(`(${conditionFilters})`);
    }

    if (query.minPrice)
      algoliaFilters.push(`(sortPrice >= ${parseFloat(query.minPrice)})`);

    if (query.maxPrice)
      algoliaFilters.push(`(sortPrice <= ${parseFloat(query.maxPrice)})`);

    algoliaFilters.push(`(sold:false)`);
    if (query.isDiscounted) algoliaFilters.push(`(discount > 0)`);

    const modelWords = query.model ? query.model.split(" ") : [];

    const { hits, nbPages } = await currentIndex.search<{ objectID: string }>(
      query.search,
      {
        filters: algoliaFilters.join(" AND "),
        hitsPerPage: productsPerPage,
        page: currentPage - 1,
        typoTolerance: "min",
        optionalWords: modelWords,
      }
    );

    return { hits: hits.map((hit) => hit.objectID), totalPages: nbPages };
  } catch (error) {
    console.error("Algolia search error:", error);
    return { hits: [], totalPages: 0 };
  }
}

export function createQueryFromSearchParams(searchParams: URLSearchParams) {
  let query: Query = { ...defaultQuery };

  const categoryParam = searchParams.get("category");
  const storeParam = searchParams.get("store");
  const brandParam = searchParams.getAll("brand");
  const conditionParam = searchParams.getAll("condition");
  const minPriceParam = searchParams.get("minPrice");
  const maxPriceParam = searchParams.get("maxPrice");
  const discountedParam = searchParams.get("discounted");
  //const soldOutParam = searchParams.get("soldOut");
  const searchParam = searchParams.get("search");
  const sortParam = searchParams.get("sort");
  const pageParam = searchParams.get("page");

  if (categoryParam) {
    query.category = { value: categoryParam, label: categoryParam };
  }

  if (storeParam) {
    query.store = storeParam;
  }

  if (brandParam.length > 0) {
    const selectedBrands = brandParam
      .map((param) => brandMap.find((option) => option.value === param))
      .filter(Boolean) as Option[];
    query.brands = selectedBrands;
  }

  if (conditionParam.length > 0) {
    const selectedConditions = conditionParam
      .map((param) => conditionMap.find((option) => option.value === param))
      .filter(Boolean) as Option[];
    query.conditions = selectedConditions;
  }

  if (minPriceParam) {
    query.minPrice = minPriceParam;
  }

  if (maxPriceParam) {
    query.maxPrice = maxPriceParam;
  }

  if (discountedParam) {
    query.isDiscounted = discountedParam === "true";
  }

  /* if (soldOutParam) {
    query.isSoldOut = soldOutParam === 'true';
  } */

  if (searchParam) {
    query.search = searchParam;
  }

  if (sortParam) {
    query.sortBy = sortMap.find((option) => option.value === sortParam) || null;
  }

  if (pageParam) {
    query.page = parseInt(pageParam);
  }

  return query;
}

export function createSearchParamsFromQuery(query: Query) {
  const params = new URLSearchParams();

  if (query.page > 1) params.set("page", query.page.toString());
  if (query.sortBy) params.set("sort", query.sortBy.value);
  if (query.category) params.set("category", query.category.value);
  if (query.search) params.set("search", query.search);
  query.brands.forEach((brand) => {
    params.append("brand", brand.value);
  });
  query.conditions.forEach((condition) => {
    params.append("condition", condition.value);
  });
  if (query.minPrice) params.set("minPrice", query.minPrice);
  if (query.maxPrice) params.set("maxPrice", query.maxPrice);
  if (query.isDiscounted) params.set("discounted", "true");
  if (query.store) params.set("store", query.store);
  // if (query.isSoldOut) params.set("soldOut", "true");

  return params;
}
