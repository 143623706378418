import { FaArrowLeft } from 'react-icons/fa';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const generatePageNumbers = (
  totalPages: number,
  currentPage: number,
  siblingCount: number = 2
) => {
  const pages = [];
  const startPage = Math.max(2, currentPage - siblingCount);
  const endPage = Math.min(totalPages - 1, currentPage + siblingCount);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;
};

export default function Pagination({
  totalPages,
  currentPage,
  onPageChange,
}: Props) {
  const pageNumbers = generatePageNumbers(totalPages, currentPage);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      scrollToTop();
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      scrollToTop();
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    scrollToTop();
    onPageChange(page);
  };

  return (
    <div className="flex justify-center mt-4 space-x-2">
      {currentPage !== 1 && (
        <button onClick={handlePrevClick}>
          <RiArrowLeftSLine className="text-xl" />
        </button>
      )}

      <button
        onClick={() => handlePageClick(1)}
        className={`px-3 py-1 ${
          currentPage === 1 ? 'bg-black text-white' : ''
        }`}
      >
        1
      </button>

      {pageNumbers.length > 0 && pageNumbers[0] > 2 && (
        <span className="pt-1">...</span>
      )}

      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => handlePageClick(page)}
          className={`px-3 py-1 ${
            page === currentPage ? 'bg-black text-white' : ''
          }`}
        >
          {page}
        </button>
      ))}

      {pageNumbers.length > 0 &&
        pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
          <span className="pt-1">...</span>
        )}

      {totalPages > 1 && (
        <button
          onClick={() => handlePageClick(totalPages)}
          className={`px-3 py-1 ${
            currentPage === totalPages ? 'bg-black text-white' : ''
          }`}
        >
          {totalPages}
        </button>
      )}

      {currentPage !== totalPages && (
        <button onClick={handleNextClick}>
          <RiArrowRightSLine className="text-xl" />
        </button>
      )}
    </div>
  );
}
