import { useEffect, useState } from "react";
import { Product } from "../lib/types";
import { MdCompareArrows, MdOutlineDiscount } from "react-icons/md";
import { RiHeartAdd2Line } from "react-icons/ri";
import { RiHeart3Fill } from "react-icons/ri";
import ClickPropagationBlocker from "./common/ClickPropagationBlocker";
import Price from "./Price";
import Percentage from "./Percentage";
import PriceRating from "./PriceRating";
import BuyButton from "./common/BuyButton";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../App";
import { FaBalanceScale, FaBalanceScaleLeft, FaCheck } from "react-icons/fa";

interface Props {
  product: Product;
  isSelected: boolean;
  handleSelectProduct: (product: Product) => void;
}

const Card = ({ product, isSelected, handleSelectProduct }: Props) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { setNumLikedProducts } = useOutletContext<OutletContext>();

  const hasDiscount = product.discount > 0;
  const isSold = product.sold;

  useEffect(() => {
    const likedProducts =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    setIsLiked(likedProducts.includes(product.id));
  }, [product.id]);

  useEffect(() => {
    const likedProducts =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    setNumLikedProducts(likedProducts.length);
  }, [isLiked]);

  const toggleHeart = () => {
    const likedProducts: string[] =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    if (isLiked) {
      const updatedProducts = likedProducts.filter((id) => id !== product.id);
      localStorage.setItem("likedProducts", JSON.stringify(updatedProducts));
    } else {
      likedProducts.push(product.id);
      localStorage.setItem("likedProducts", JSON.stringify(likedProducts));
    }
    setIsLiked(!isLiked);
  };

  const isNewProduct = () => {
    try {
      const now = new Date();
      const productDate = new Date(product.createdAt.seconds * 1000);
      const timeDifference = now.valueOf() - productDate.valueOf();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference < 24 * 7;
    } catch (error) {
      console.error("Error in isNewProduct:", error, "Product:", product);
      return false;
    }
  };

  let discountPercentage = 0.0;
  if (hasDiscount) {
    discountPercentage = (product.price - product.discount) / product.price;
  }

  return (
    <div
      className={`overflow-hidden flex flex-col w-full h-full 
        ${!isSold && "hover:cursor-pointer"}`}
    >
      <div className="relative w-full">
        <div className="w-full aspect-square overflow-hidden rounded-lg bg-white">
          <img
            loading="lazy"
            src={product.thumbnailUrl}
            className={`w-full object-cover transition-transform duration-300 ease-in-out rounded scale-[1.01]
              ${!isSold && "hover:scale-105"}`}
            alt=""
          />
          {isSold && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <p className="font-sans text-2xl font-bold text-white">SOLD</p>
            </div>
          )}
        </div>
        {isNewProduct() && (
          <div className="absolute transform -rotate-45 bg-accentLight text-center text-accentDark font-sans font-semibold text-[12px] left-[-80px] top-[10px] w-[200px]">
            New
          </div>
        )}
        {hasDiscount ? (
          <div className="flex font-sans text-xs font-semibold p-1 absolute top-1 right-1 rounded-full px-2 bg-discount text-white align-middle items-center">
            <MdOutlineDiscount className="mr-0.5 text-sm" />
            <p>
              <Percentage value={discountPercentage} />
            </p>
          </div>
        ) : null}
        <ClickPropagationBlocker>
          <button
            id="card-compare-button"
            className={`absolute bottom-1 left-1 rounded-full p-2 drop-shadow 
      ${
        isSelected
          ? "bg-black text-white"
          : "bg-white text-black hover:border border-gray-400"
      }`}
            onClick={() => handleSelectProduct(product)}
          >
            <FaBalanceScaleLeft className="text-xl" />
          </button>
          <button
            id="card-like-button"
            className={`absolute bottom-1 right-1 ${
              isLiked ? "text-discount" : "text-black"
            } bg-white rounded-full p-2 drop-shadow hover:border border-gray-400`}
            onClick={toggleHeart}
          >
            {isLiked ? (
              <RiHeart3Fill className="text-xl" />
            ) : (
              <RiHeartAdd2Line className="text-xl" />
            )}
          </button>
        </ClickPropagationBlocker>
      </div>
      <div className="flex flex-grow flex-row py-1 px-1">
        <div className="flex flex-col flex-grow leading-normal">
          <div className="flex flex-col flex-grow">
            <p className="font-serif font-medium text-base sm:text-lg uppercase">
              {product.brand}
            </p>
            <p className="font-sans text-sm sm:text-base">{product.model}</p>
            <p className="font-sans text-sm sm:text-base">
              Condition: {product.condition ?? "Unknown"}
            </p>
            {hasDiscount ? (
              <div className="flex flex-row mt-2">
                <p className="font-sans text-sm sm:text-base font-bold mr-2 text-discount">
                  <Price
                    amount={product.discount}
                    currency={product.currency}
                  />
                </p>
                <p className="font-sans text-xs sm:text-sm mt-1 mr-2 text-gray-500 line-through decoration-1">
                  <Price amount={product.price} currency={product.currency} />
                </p>
              </div>
            ) : (
              <div className="mt-1">
                <p className="font-sans text-sm sm:text-base font-bold ">
                  <Price amount={product.price} currency={product.currency} />
                </p>
              </div>
            )}
            <div className="">
              <PriceRating product={product} />
            </div>
            {/* <p className="font-sans text-sm sm:text-base italic text-gray-400 mt-2">
              By {product.store}
            </p> */}
          </div>
        </div>
      </div>
      <ClickPropagationBlocker>
        {!product.sold && (
          <div className="flex m-2 justify-center sm:justify-start">
            <BuyButton
              product={product}
              mods="text-xs sm:text-sm btn-transparent border-transparent text-black px-2 shadow-md rounded-md bg-black text-white"
            />
          </div>
        )}
      </ClickPropagationBlocker>
    </div>
  );
};

export default Card;
