import React from "react";

interface Props {
  value: number;
  decimals?: boolean;
}

export default function Number({ value, decimals = false }: Props) {
  const locale = navigator.language;

  const formattedNumber = new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  }).format(value);

  return <span>{formattedNumber}</span>;
}
