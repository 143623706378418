import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

interface MenuItemProps {
  to: string;
  label: string;
  onClick: () => void;
  id: string;
}

const MenuItem = ({ to, label, onClick, id }: MenuItemProps) => {
  return (
    <Link
      id={id}
      to={to}
      className="flex justify-between items-center py-2 border-gray-200"
      onClick={onClick}
      reloadDocument
    >
      <span>{label}</span>
    </Link>
  );
};

interface MenuItemLinkProps {
  label: string;
  url: string;
  onClick: () => void;
  id: string;
}

const MenuItemLink = ({ label, url, onClick, id }: MenuItemLinkProps) => {
  return (
    <a
      id={id}
      href={url}
      className="flex justify-between items-center py-2 text-lg border-gray-200"
      onClick={onClick}
    >
      <span>{label}</span>
    </a>
  );
};

interface SubmenuProps {
  title: string;
  children: ReactNode;
}

const Submenu = ({ title, children }: SubmenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="cursor-pointer border-b" onClick={toggle}>
      <div className="flex justify-between items-center px-4 mt-4 mb-2">
        <h2 className="text-lg font-sans">{title}</h2>
        <div>
          {isOpen ? (
            <RiArrowUpSLine className="text-xl" />
          ) : (
            <RiArrowDownSLine className="text-xl" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col bg-gray-100 w-full pl-4 ">{children}</div>
      )}
    </div>
  );
};

interface Props {
  isOpen: boolean;
  toggleMenu: () => void;
}

const Menu = ({ isOpen, toggleMenu }: Props) => {
  return (
    <div>
      <div
        className={`justify-between fixed inset-y-0 left-0 bg-white w-80  transition-transform duration-300 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } z-50 shadow-lg`}
      >
        <div className="flex flex-col">
          <div>
            <Submenu title={"Bags"}>
              <MenuItem
                to="/products"
                label="All bags"
                onClick={toggleMenu}
                id="menu-all-bags-link"
              />
              <MenuItem
                to="/products?category=hand-bag"
                label="Handbags"
                onClick={toggleMenu}
                id="menu-handbags-link"
              />
              <MenuItem
                to="/products?category=shoulder-bag"
                label="Shoulder bags"
                onClick={toggleMenu}
                id="menu-shoulder-bags-link"
              />
              <MenuItem
                to="/products?category=crossbody-bag"
                label="Crossbody bags"
                onClick={toggleMenu}
                id="menu-crossbody-bags-link"
              />
              <MenuItem
                to="/products?category=tote-bag"
                label="Totes"
                onClick={toggleMenu}
                id="menu-totes-link"
              />
              <MenuItem
                to="/products?category=weekend-bag"
                label="Weekend bags"
                onClick={toggleMenu}
                id="menu-weekend-bags-link"
              />
              <MenuItem
                to="/products?category=clutch"
                label="Clutch bags"
                onClick={toggleMenu}
                id="menu-clutch-bags-link"
              />
              <MenuItem
                to="/products?category=belt-bag"
                label="Belt bags"
                onClick={toggleMenu}
                id="menu-belt-bags-link"
              />
              <MenuItem
                to="/products?category=backpack"
                label="Backpacks"
                onClick={toggleMenu}
                id="menu-backpacks-link"
              />
            </Submenu>
          </div>
          <div className="pl-4 pt-2 text-lg">
            <MenuItem
              to="/brands"
              label="Brands"
              onClick={toggleMenu}
              id="menu-brands-link"
            />
            <MenuItem
              to="/products?sort=createdAt"
              label="New In"
              onClick={toggleMenu}
              id="menu-new-in-link"
            />
            <MenuItem
              to="/products?discounted=true"
              label="Sale"
              onClick={toggleMenu}
              id="menu-sale-link"
            />
          </div>
          <div className="mt-4 pl-4">
            <MenuItemLink
              label="Blog"
              url="https://blog.avenura.com"
              onClick={toggleMenu}
              id="menu-blog-link"
            />
            <MenuItemLink
              label="Newsletter"
              url="https://blog.avenura.com/newsletter"
              onClick={toggleMenu}
              id="menu-newsletter-link"
            />
            <MenuItemLink
              label="Partners"
              url="https://blog.avenura.com/partners"
              onClick={toggleMenu}
              id="menu-partners-link"
            />
            <MenuItemLink
              label="About Us"
              url="https://blog.avenura.com/about"
              onClick={toggleMenu}
              id="menu-about-us-link"
            />
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={toggleMenu}
      ></div>
    </div>
  );
};

export default Menu;
