import {
  collection,
  getDocs,
  limit,
  query as firestoreQuery,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { db } from "../lib/firebase";
import { RiHeart3Fill, RiHeartAdd2Line } from "react-icons/ri";
import Carousel from "../components/Carousel";
import BuyFooter from "../components/product/BuyFooter";
import Button from "../components/common/Button";
import {
  HiArrowLongRight,
  HiChevronDown,
  HiChevronUp,
  HiSquare2Stack,
} from "react-icons/hi2";
import { Product } from "../lib/types";
import { defaultQuery, fuzzySearch, Query } from "../lib/search";
import Price from "../components/Price";
import Grid from "../components/products/Grid";
import { OutletContext } from "../App";
import ProductPageSkeleton from "../components/product/ProductPagePlaceholder";
import NotFound from "../components/NotFound";
import PriceRating from "../components/PriceRating";
import { useMediaQuery } from "react-responsive";
import BuyButton from "../components/common/BuyButton";
import { FaBalanceScaleLeft } from "react-icons/fa";

interface InfoProps {
  label: string;
  text: string;
}

const Info = ({ label, text }: InfoProps) => {
  return (
    <div className="border-b mt-4 pb-2">
      <p className="font-sans text-lg font-bold">{label}</p>
      <p className="font-sans text-md">{text}</p>
    </div>
  );
};

const SimilarProducts = ({ product }: { product: Product }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const productsPerPage = 8;

  const fetchData = async () => {
    setLoading(true);
    let query: Query = { ...defaultQuery };
    query.model = product.model;
    query.search = product.brand + " " + product.model;
    const { hits: ids, totalPages } = await fuzzySearch(
      query,
      1,
      productsPerPage
    );
    const filteredIds = ids.filter((id) => id !== product.id);
    if (filteredIds.length === 0) {
      return;
    }

    const productsQuery = firestoreQuery(
      collection(db, "products"),
      where("__name__", "in", filteredIds),
      limit(productsPerPage)
    );
    const snapshot = await getDocs(productsQuery);
    const products = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Product[];

    setProducts(products);
    setLoading(false);
  };

  useEffect(() => {
    if (product) {
      fetchData();
    }
  }, [product]);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link
          to={`/products?search=${
            product ? product.brand + " " + product.model : ""
          }`}
          reloadDocument
        >
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">
              Similar products
            </h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <Grid
          products={products}
          loading={loading}
          numProducts={8}
          reloadDocument={true}
        />
      </div>
    </section>
  );
};

const ProductPage = () => {
  const { productId } = useParams();
  const { isComparing, setSelectedProducts } =
    useOutletContext<OutletContext>();
  const [loading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(null);
  const [showFooter, setShowFooter] = useState(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const toggleHeart = () => {
    const likedProducts: string[] =
      JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
    if (isLiked) {
      const updatedProducts = likedProducts.filter((id) => id !== product.id);
      localStorage.setItem("likedProducts", JSON.stringify(updatedProducts));
    } else {
      likedProducts.push(product.id);
      localStorage.setItem("likedProducts", JSON.stringify(likedProducts));
    }
    setIsLiked(!isLiked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      const docRef = doc(db, "products", productId || "");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        if (window.location.hostname === "localhost") {
          console.log("Document data:", docSnap.data());
        }
        setProduct(docSnap.data());
        const likedProducts =
          JSON.parse(localStorage.getItem("likedProducts") || "[]") || [];
        setIsLiked(likedProducts.includes(productId));
        setLoading(false);
      } else {
        setLoading(false);
        setNotFound(true);
      }
    };

    fetchProduct();
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowFooter(scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function formatDescription(description: string) {
    return description.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  if (loading) {
    return <ProductPageSkeleton />;
  }

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="pb-4">
      <Helmet>
        <title>{"AVENURA - " + product.brand + " " + product.model}</title>
        <meta
          name="description"
          content={product.description || "AVENURA - The Designer Marketplace"}
        />
        <meta
          property="og:title"
          content={product.brand + " " + product.model}
        />
        <meta
          property="og:description"
          content={product.description || "AVENURA - The Designer Marketplace"}
        />
        <meta property="og:image" content={product.thumbnailUrl} />
        <link
          rel="canonical"
          href={`https://www.avenura.com/product/${product.id}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: product.model,
            image: product.thumbnailUrl,
            //"description": product.description,
            brand: {
              "@type": "Brand",
              name: product.brand,
            },
            offers: {
              "@type": "Offer",
              url: `https://www.avenura.com/product/${product.id}`,
              priceCurrency: product.currency,
              price: product.price,
            },
          })}
        </script>
      </Helmet>
      <div className="flex flex-col lg:flex-row mt-4">
        <div className="relative w-full lg:w-1/2 aspect-square px-4">
          {product.sold ? (
            <div className="relative">
              <img
                src={product.imageUrl}
                alt={`${product.brand} ${product.model}`}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-3xl font-bold">
                SOLD OUT
              </div>
            </div>
          ) : (
            <Carousel images={[product.imageUrl, ...product.imageUrls]}>
              <button
                className={`absolute bottom-2 left-2 rounded-full p-2 drop-shadow ${
                  isComparing
                    ? "bg-black text-white"
                    : "bg-white text-black hover:border border-gray-400"
                } z-50`}
                onClick={() =>
                  setSelectedProducts((prev) => [...prev, product])
                }
              >
                <FaBalanceScaleLeft className="text-xl" />
              </button>
              <button
                className={`absolute bottom-2 right-2 ${
                  isLiked ? "text-heartColor" : "text-black"
                } bg-white rounded-full p-2 drop-shadow hover:border border-gray-400 z-50`}
                onClick={toggleHeart}
              >
                {isLiked ? (
                  <div>
                    <RiHeart3Fill className="text-xl" />
                  </div>
                ) : (
                  <RiHeartAdd2Line className="text-xl" />
                )}
              </button>
            </Carousel>
          )}
        </div>
        <div className="w-full lg:w-1/2 lg:pl-5 lg:mt-0 px-4">
          <div className="">
            <div className="font-serif text-2xl font-bold">
              {product.brand}, {product.model}
            </div>
            <div className="">{product.condition ?? "Unknown"} Condition</div>
            {product.discount === 0 ? (
              <div className="">
                <Price amount={product.price} currency={product.currency} />
              </div>
            ) : (
              <div>
                <span className="text-red-500 mr-2">
                  <Price
                    amount={product.discount}
                    currency={product.currency}
                  />
                </span>
                <span className="line-through decoration-1">
                  <Price amount={product.price} currency={product.currency} />
                </span>
              </div>
            )}
            <PriceRating product={product} />
            {!product.sold && (
              <div className="mt-6 flex justify-center sm:justify-start">
                <BuyButton product={product} />
              </div>
            )}
          </div>

          <div className="mt-6">
            <h1 className="font-serif text-2xl font-bold">Product Details</h1>
            <div className="mt-2">
              <h2 className="font-sans font-bold">Color and Material</h2>
              <p>
                {product.color ? product.color + ", " : ""}
                {product.material ?? ""}
                {product.color == null && product.material == null
                  ? "Not available"
                  : ""}
              </p>
            </div>
            <div className="mt-2">
              <h2 className="font-sans font-bold">Measurements</h2>
              {product.length && (
                <p className="font-sans text-md">Length: {product.length} cm</p>
              )}
              {product.width && (
                <p className="font-sans text-md">Width: {product.width} cm</p>
              )}
              {product.height && (
                <p className="font-sans text-md">Height: {product.height} cm</p>
              )}
              {product.length == null &&
              product.width == null &&
              product.height == null
                ? "Not available"
                : ""}
            </div>
          </div>

          <div className="mt-4 pb-2">
            {isDesktop ? (
              <>
                <div className="flex items-center space-x-2">
                  <p className="font-sans font-bold">Description</p>
                </div>
                <p className={`font-sans text-md`}>
                  {product.description
                    ? formatDescription(product.description)
                    : "Description not available"}
                </p>
              </>
            ) : (
              <>
                <div className="flex items-center space-x-2">
                  <p className="font-sans font-bold">Description</p>
                  <button
                    type="button"
                    className={`flex ${
                      isDescriptionCollapsed ? "text-gray-600" : "text-gray-500"
                    } mr-2`}
                    onClick={() =>
                      setIsDescriptionCollapsed(!isDescriptionCollapsed)
                    }
                  >
                    {isDescriptionCollapsed ? (
                      <HiChevronDown className="text-lg" />
                    ) : (
                      <HiChevronUp className="text-lg" />
                    )}
                  </button>
                </div>
                <p
                  className={`font-sans text-md ${
                    isDescriptionCollapsed ? "hidden" : ""
                  }`}
                >
                  {product.description
                    ? formatDescription(product.description)
                    : "Description not available"}
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <SimilarProducts product={product} />

      {!loading && showFooter && !product.sold && !isComparing && (
        <BuyFooter product={product} />
      )}
    </div>
  );
};

export default ProductPage;
