import React from 'react';
import { RiFilter2Line } from 'react-icons/ri';

interface Props {
  toggleFilter: () => void;
}

export default function FilterButton({ toggleFilter }: Props) {
  return (
    <div className="flex items-center cursor-pointer" onClick={toggleFilter}>
      <RiFilter2Line className="text-2xl cursor-pointer my-2 mr-1" />
      <p className="align-middle font-sans">Filter</p>
    </div>
  );
}
