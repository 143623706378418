import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function GridPlaceholder({
  numProducts,
}: {
  numProducts: number;
}) {
  return (
    <div className="flex flex-wrap">
      {Array.from({ length: numProducts }).map((_, index) => {
        return (
          <div
            key={index}
            className="flex flex-col w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
          >
            <Skeleton
              className="w-full h-full aspect-square rounded-lg"
              style={{ borderRadius: "10px" }}
            />
            <div className="flex flex-grow flex-row text-sm sm:text-sm md:text-md lg:text-lg py-1 px-2">
              <div className="flex flex-grow flex-row text-sm py-1 px-2 mt-4">
                <div className="flex flex-col flex-grow">
                  <Skeleton height={20} width="80%" className="mb-2" />
                  <Skeleton height={15} width="60%" className="mb-1" />
                  <Skeleton height={15} width="50%" className="mb-1" />
                  <Skeleton height={15} width="70%" className="mb-1" />
                  <div className="flex flex-col mt-1">
                    <Skeleton height={15} width="50%" className="mb-1" />
                    <Skeleton height={15} width="50%" className="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
