import React, { useEffect, useState } from "react";
import { HiArrowLongRight } from "react-icons/hi2";

const LeavingPage = () => {
  const [countdown, setCountdown] = useState(5);
  const searchParams = new URLSearchParams(window.location.search);
  const externalUrl = searchParams.get("externalUrl") || "";
  const store = searchParams.get("store") || "";

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = externalUrl;
    }
  }, [countdown, externalUrl]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background px-4 sm:px-8">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center">
        You are leaving Avenura
      </h1>
      <p className="text-lg sm:text-xl mb-8 text-center">
        {store
          ? `You will be redirected to ${store} in `
          : "You will be redirected in "}
        {countdown} seconds.
      </p>
      <button
        className="btn btn-primary flex items-center space-x-2 px-6 py-3 text-lg text-center justify-center"
        onClick={() => (window.location.href = externalUrl)}
      >
        <span>Go now</span>
        <HiArrowLongRight className="text-2xl" />
      </button>
    </div>
  );
};

export default LeavingPage;
