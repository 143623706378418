import { useEffect, useState } from 'react';
import Select, {
  MultiValue,
  OptionProps,
  SingleValue,
  components,
} from 'react-select';
import { brandMap, categoryOptions, conditionMap } from '../lib/types';
import { Option } from '../lib/types';
import { Query } from '../lib/search';

export interface PriceFilter {
  minPrice: number | undefined;
  maxPrice: number | undefined;
}

interface FilterOptionProps {
  filter: any;
  setFilter: (option: any) => void;
  options: Option[];
  placeholder: string;
}

const FilterOption = ({
  filter,
  setFilter,
  options,
  placeholder,
}: FilterOptionProps) => {
  return (
    <div className="mb-4">
      <Select
        value={filter}
        onChange={setFilter}
        options={options}
        placeholder={placeholder}
        className="font-sans"
        isClearable
        isSearchable
      />
    </div>
  );
};

const OptionComponent = (props: OptionProps<Option>) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="accent-black"
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

interface Props {
  query: Query;
  setQuery: (query: Query) => void;
  isOpen: boolean;
  close: () => void;
}

const FilterMenu = ({ query, setQuery, isOpen, close }: Props) => {
  const [categoryFilter, setCategoryFilter] =
    useState<SingleValue<Option> | null>(query.category);
  const [brandsFilter, setBrandsFilter] = useState<MultiValue<Option>>(
    query.brands
  );
  const [conditionsFilter, setConditionsFilter] = useState<MultiValue<Option>>(
    query.conditions
  );
  const [minPriceFilter, setMinPriceFilter] = useState<string>(query.minPrice);
  const [maxPriceFilter, setMaxPriceFilter] = useState<string>(query.maxPrice);
  const [isDiscountedFilter, setIsDiscountedFilter] = useState<boolean>(
    query.isDiscounted
  );
  /* const [isSoldOutFilter, setIsSoldOutFilter] = useState<boolean>(
    query.isSoldOut
  ); */

  useEffect(() => {
    setCategoryFilter(query.category);
    setBrandsFilter(query.brands);
    setConditionsFilter(query.conditions);
    setMinPriceFilter(query.minPrice);
    setMaxPriceFilter(query.maxPrice);
    setIsDiscountedFilter(query.isDiscounted);
  }, [query]);

  brandMap.sort((a, b) => a.label.localeCompare(b.label));

  function handleApply() {
    setQuery({
      ...query,
      page: 1,
      category: categoryFilter,
      brands: brandsFilter,
      conditions: conditionsFilter,
      minPrice: minPriceFilter,
      maxPrice: maxPriceFilter,
      isDiscounted: isDiscountedFilter,
      //isSoldOut: isSoldOutFilter,
    });
    close();
  }

  function clearAll() {
    setCategoryFilter(null);
    setBrandsFilter([]);
    setConditionsFilter([]);
    setMinPriceFilter('');
    setMaxPriceFilter('');
    setIsDiscountedFilter(false);
    //setIsSoldOutFilter(false);
  }

  return (
    <div>
      <div
        className={`fixed inset-y-0 left-0 bg-white w-80 p-4 transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } z-50 shadow-lg`}
      >
        <h2 className="text-xl font-serif font-semibold mb-4">Filter</h2>
        {/* <div className="mb-4">
          <Select
            value={orderByOptions.find(option => option.value === sortBy)}
            onChange={setSortBy}
            options={orderByOptions}
            placeholder="Sort By"
            className="font-sans"
          />
        </div> */}
        <div className="mb-4">
          <Select
            value={categoryFilter}
            onChange={setCategoryFilter}
            options={categoryOptions}
            placeholder={'Category'}
            className="font-sans"
            isSearchable={false}
            isClearable
            // components={{ Option: OptionComponent }}
            // styles={{
            //   option: base => ({
            //     ...base,
            //     colors: {
            //       primary25: 'hotpink',
            //       primary: 'black',
            //     },
            //   }),
            // }}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#ccc',
                primary: 'black',
              },
            })}
          />
        </div>
        <div className="mb-4">
          <Select
            value={brandsFilter}
            onChange={setBrandsFilter}
            options={brandMap}
            placeholder={'Brand'}
            className="font-sans"
            isClearable
            isSearchable
            isMulti
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: OptionComponent }}
            styles={{
              option: base => ({
                margin: '0.5rem',
              }),
            }}
          />
        </div>
        {/* <div className="mb-4">
          <input
            type="text"
            placeholder="Model"
            className="border rounded border-gray-300 font-sans w-full p-1.5 px-2 text-gray-500"
            value={modelFilter}
            onChange={e => setModelFilter(e.target.value)}
          />
        </div> */}
        <div className="mb-4">
          <Select
            value={conditionsFilter}
            onChange={setConditionsFilter}
            options={conditionMap}
            placeholder={'Condition'}
            className="font-sans"
            isSearchable={false}
            isClearable
            isMulti
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: OptionComponent }}
            styles={{
              option: base => ({
                margin: '0.5rem',
              }),
            }}
          />
        </div>
        {/* <ReactSlider
          className="border h-12 my-2 align-items items-center align-middle"
          thumbClassName="example-thumb"
          trackClassName="bg-slate-500"
          defaultValue={[0, 500]}
          renderThumb={(props, state) => (
            <div
              {...props}
              className="border  cursor-pointer rounded-full w-8 text-center"
            >
              {state.valueNow}
            </div>
          )}
          pearling
          minDistance={10}
          min={0}
          max={500000}
        /> */}
        <div className="mb-4">
          <input
            id="maxPrice"
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={maxPriceFilter}
            onChange={e => setMaxPriceFilter(e.target.value)}
            placeholder="Max price"
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>
        <div className="mb-4">
          <input
            id="minPrice"
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={minPriceFilter}
            onChange={e => setMinPriceFilter(e.target.value)}
            placeholder="Min price"
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>
        <div className="mb-4 font-sans">
          <div
            className="flex items-center mb-4 cursor-pointer"
            onClick={() => setIsDiscountedFilter(!isDiscountedFilter)}
          >
            <input
              id="discounted"
              type="checkbox"
              checked={isDiscountedFilter}
              onChange={() => null}
              className="mr-2 cursor-pointer"
            />{' '}
            <label className="cursor-pointer">On Sale</label>
          </div>
        </div>
        {/* <div className="mb-4 font-sans">
          <div
            className="flex items-center mb-4 cursor-pointer"
            onClick={() => setIsSoldOutFilter(!isSoldOutFilter)}
          >
            <input
              id="sold"
              type="checkbox"
              checked={isSoldOutFilter}
              onChange={() => null}
              className="mr-2 cursor-pointer"
            />{' '}
            <label className="cursor-pointer">Include Sold</label>
          </div>
        </div> */}
        <button
          className="w-full border py-2 font-sans border-black"
          onClick={handleApply}
        >
          Apply
        </button>
        <button
          className="w-full border py-2 font-sans mt-2"
          onClick={clearAll}
        >
          Clear All
        </button>
      </div>
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 ${
          isOpen ? 'block' : 'hidden'
        }`}
        onClick={close}
      ></div>
    </div>
  );
};

export default FilterMenu;
