import React from 'react';

interface Props {
  title: string;
  onClick: () => void;
  primary?: boolean;
  secondary?: boolean;
  id?: string;
}

export default function Button({
  title,
  onClick,
  primary,
  secondary,
  id,
}: Props) {
  const isPrimary = primary ?? true;
  const isSecondary = secondary ?? false;

  const primaryCss = 'bg-black text-white border-black';
  const secondaryCss = 'bg-white text-black border-black';

  return (
    <button
      id={id ?? ''}
      onClick={onClick}
      className={`border rounded-md p-2 px-4 font-sans w-full sm:w-auto ${
        isSecondary ? secondaryCss : primaryCss
      }`}
    >
      <span>{title}</span>
    </button>
  );
}
